<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="login-pane-cjp">
    <div class="login-pane-cjp__help-text">
      <h2 class="title-l">
        {{
          content && content.title ? content.title : "Log into your account" | t
        }}
      </h2>
      <div
        v-if="content && content.intro"
        class="redactor"
        v-html="content.intro"
      ></div>
      <p v-if="helptext">{{ helptext }}</p>
    </div>

    <form>
      <div class="field">
        <label class="label">{{ "Email address" | t }}</label>
        <input
          ref="username"
          v-model="username"
          type="email"
          name="username"
          :class="{ 'is-error': errors.username }"
          required
        />
        <p v-if="errors.username" class="help is-error">
          {{ errors.username }}
        </p>
      </div>

      <div class="field">
        <label class="label">{{ "Password" | t }}</label>
        <input
          ref="password"
          v-model="password"
          type="password"
          name="password"
          :class="{ 'is-error': errors.password }"
          required
        />

        <div class="password-icon" @click.prevent="togglePassword">
          <i v-if="passwordHidden" class="far fa-eye"></i>
          <i v-else class="far fa-eye-slash"></i>
        </div>

        <p v-if="errors.password" class="help is-error">
          {{ errors.password }}
        </p>
      </div>

      <div class="field">
        <p v-if="errorMessage" class="help is-error">{{ errorMessage }}</p>

        <button class="button--primary" @click.prevent="login">
          {{ "Login" | t }}
        </button>
      </div>

      <div v-if="accountLinks" class="form-footer">
        <div class="form-footer__list">
          <div
            v-for="(accountLink, index) in accountLinks"
            :key="`link-${index}`"
            class="form-footer__link-wrap"
          >
            <a class="form-footer__link" :href="accountLink.url">{{
              accountLink.text
            }}</a>
          </div>
        </div>
        <div v-if="supportLink" class="form-footer__support">
          <a :href="supportLink.url">
            {{ supportLink.text }}
            <div class="icon">
              <i class="far fa-comment"></i>
            </div>
          </a>
        </div>
      </div>
    </form>

    <button class="close" @click="close">
      {{ "sluit" | t }} <i class="far fa-times"></i>
    </button>
  </div>
</template>

<script>
import AuthorizationError from "../../../errors/AuthorizationError";
import user from "../../../services/userServices/cjpUserService";

export default {
  name: "LoginPaneCjp",
  props: {
    helptext: {
      type: String,
      default: null,
    },
    accountLinks: {
      type: Array,
      default: null,
    },
    returnTo: {
      type: String,
      default: null,
    },
    supportLink: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errors: {},
      username: null,
      password: null,
      errorMessage: null,
      passwordHidden: true,
    };
  },
  computed: {
    title() {
      if (this.content.title) return this.content.title;
      return this.$options.filters.t("Log into your account");
    },
  },
  watch: {
    username() {
      this.resetError("username");
    },
    password() {
      this.resetError("password");
    },
  },
  methods: {
    resetError(fieldName) {
      delete this.errors[fieldName];

      if (Object.keys(this.errors).length === 0) {
        this.errorMessage = null;
      }
    },
    validate() {
      let isValid = true;
      if (!this.$refs.username.checkValidity()) {
        isValid = false;
        this.errors.username = this.$refs.username.validationMessage;
      }

      if (!this.$refs.password.checkValidity()) {
        isValid = false;
        this.errors.password = this.$refs.password.validationMessage;
      }

      if (!isValid) {
        this.errorMessage = this.$options.filters.t(
          "Please check your credentials"
        );
      }

      return isValid;
    },
    async login() {
      if (!this.validate()) {
        return;
      }

      const loginParams = {
        username: this.username,
        password: this.password,
        returnTo: document.referrer !== location.href ? document.referrer : "/",
      };

      if (this.returnTo) {
        loginParams.returnTo = this.returnTo;
      }

      try {
        await user.doLogin(loginParams);
      } catch (err) {
        if (err instanceof AuthorizationError) {
          this.errorMessage = this.$options.filters.t(
            "Login failed. Please try again!"
          );
        } else {
          throw err;
        }
      }
    },
    close() {
      if (!document.referrer.includes(window.location.hostname)) {
        window.location.href = "/";
      } else {
        window.history.length > 1 ? window.history.back() : window.close();
      }
    },
    togglePassword() {
      this.passwordHidden = !this.passwordHidden;
      this.$refs.password.type = this.passwordHidden ? "password" : "text";
    },
  },
};
</script>
