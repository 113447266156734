<template>
  <div class="checkout-section">
    <h3>
      <span v-if="number > 0" class="checkout-section__number"
        >{{ number }}
      </span>
      {{ title }}
    </h3>
    <div class="checkout-section__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutSection",
  props: {
    number: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
