<template>
  <checkout-section :number="2" :title="'Pick a timeslot' | t">
    <template #content>
      <small v-if="availabilityMessage" class="text text--error">{{
        availabilityMessage
      }}</small>

      <div v-if="localTimeslots[date]" class="timeslot-selector">
        <button
          v-for="(availability, time) in localTimeslots[date]"
          :key="time"
          class="timeslot-selector__timeslot"
          :class="{ 'is-active': time === selected, hidden: availability == 0 }"
          @click.prevent="handleTimeslotSelection(time, availability)"
        >
          {{ time }}
        </button>
      </div>
      <div v-if="!localTimeslots[date] && fetchingTimeslots" class="">
        Beschikbare tijdsloten worden geladen...
      </div>
    </template>
  </checkout-section>
</template>

<script>
import apiClient from "../../../services/intoCultureApiClient.js";
import IntoCultureApiClientError from "../../../errors/IntoCultureApiClientError.js";

export default {
  name: "CheckoutTimeslotSelector",
  props: {
    slug: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: null,
    },
    timeslots: {
      type: Object,
      default: null,
    },
    selected: {
      type: String,
      default: null,
    },
    orderQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localTimeslots: {},
      fetchingTimeslots: false,
      availabilityMessage: null,
    };
  },
  watch: {
    async date() {
      await this.rebuildTimeslots();
    },
    async orderQuantity() {
      await this.rebuildTimeslots();
    },
  },
  mounted() {
    if (this.timeslots) {
      this.setTimeslots(this.date, this.timeslots);
    } else if (this.date) {
      this.rebuildTimeslots();
    }
  },
  methods: {
    handleTimeslotSelection(time, availableQuantity) {
      this.$emit("timeslot-selected", time);
      this.$emit("timeslot-quantity", availableQuantity);
    },
    setTimeslots(dateString, timeslots) {
      this.localTimeslots = {
        ...this.localTimeslots,
        [dateString]: timeslots,
      };
    },
    async rebuildTimeslots() {
      try {
        if (!this.date) {
          return;
        }

        this.availabilityMessage = null;
        if (!this.localTimeslots[this.date]) {
          // We don't have data yet. show initial timeslots and spinner
          this.fetchingTimeslots = true;
          const endDate = new Date(this.date);
          endDate.setDate(endDate.getDate() + 14); // always load 14 days for better ux

          const { availability } = await apiClient.getProductAvailability(
            this.slug,
            {
              startDate: this.date,
              endDate: endDate.toISOString().substring(0, 10),
            }
          );

          if (availability) {
            for (const availabilityDate in availability) {
              this.setTimeslots(
                availabilityDate,
                availability[availabilityDate]
              );
            }
          }
          this.fetchingTimeslots = false;
        }

        if (this.localTimeslots[this.date]) {
          // we have availability :)
        } else {
          this.availabilityMessage = this.$options.filters.t(
            "No timeslot available for the selected date"
          );
        }

        if (
          this.selected &&
          this.localTimeslots[this.date][this.selected] < this.orderQuantity
        ) {
          // previously selected timeslot not available on selected date
          this.$emit("timeslot-selected", null);
        }
      } catch (err) {
        if (err instanceof IntoCultureApiClientError) {
          console.error(err.msg);
        } else {
          throw err;
        }
      }
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
</style>
