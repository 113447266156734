<template>
  <callback-loading></callback-loading>
</template>

<script>
import order from "../../../services/orderService";

export default {
  name: "CheckoutPaymentCallback",
  created() {
    order.doPaymentRedirect();
  },
};
</script>
