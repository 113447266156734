<template>
  <div class="checkout-page-wrapper">
    <checkout-header
      :step="4"
      :title="'One moment please' | t"
      :logo="headerBlock.logo"
    ></checkout-header>

    <checkout-page-order-success
      :product="product"
      :order-id="orderId"
    ></checkout-page-order-success>

    <checkout-page-error-message></checkout-page-error-message>
  </div>
</template>

<script>
import EventBus from "../../../event-bus";

export default {
  name: "CheckoutSuccess",
  props: {
    headerBlock: {
      type: Object,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setEventHandlers();
  },
  methods: {
    setEventHandlers() {
      EventBus.$on("checkoutFailed", () => {
        // this.currentStep = 2;
      });
    },
  },
};
</script>
