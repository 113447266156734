<template>
  <div class="login-pane-container">
    <login-pane-cjp
      v-if="isCjpLogin"
      :helptext="helptext"
      :return-to="returnTo"
      :account-links="accountLinks"
      :support-link="supportLink"
      loginhref="/login"
    ></login-pane-cjp>

    <login-pane-oauth v-else :helptext="helptext"></login-pane-oauth>
  </div>
</template>

<script>
import LoginPaneCjp from "./LoginPaneCjp.vue";

export default {
  name: "LoginPane",
  components: {
    LoginPaneCjp,
  },
  props: {
    helptext: {
      type: String,
      default: null,
    },
    returnTo: {
      type: String,
      default: null,
    },
    accountLinks: {
      type: Array,
      default: null,
    },
    supportLink: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isCjpLogin() {
      return process.env.VUE_APP_NAME === "cjp";
    },
  },
};
</script>
