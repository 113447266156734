<template>
  <main>
    <div class="checkout__container">
      <div class="checkout__main-content">
        <checkout-review-order-card
          :product="product"
          :order="order"
        ></checkout-review-order-card>
      </div>

      <div class="checkout__main-content">
        <div class="ticket-table--order-review">
          <div class="ticket-table__header">
            <div>{{ "Tickets" | t }}</div>
            <div>
              <a
                class="order-review__btn-edit"
                @click.prevent="$emit('edit-order')"
              >
                {{ "Change" | t }}
              </a>
            </div>
          </div>

          <template v-for="variant in order.tickets">
            <div
              v-if="variant.amount > 0"
              :key="variant.slug"
              class="ticket-table__row"
            >
              <div class="ticket-table__field">{{ variant.name }}</div>
              <div class="ticket-table__field">
                &euro;
                {{
                  parseFloat(variant.price)
                    .toFixed(2)
                    .replace(".", ",")
                }}
              </div>
              <div class="ticket-table__field">{{ variant.amount }}x</div>
              <div class="ticket-table__field">
                <strong
                  >&euro;
                  {{
                    (variant.amount * variant.price)
                      .toFixed(2)
                      .replace(".", ",")
                  }}</strong
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="ticket-table__footer__container checkout__container">
      <div class="checkout__main-content">
        <div class="ticket-table__footer">
          <div class="ticket-table__footer__label">{{ "Total" | t }}:</div>
          <div class="ticket-table__footer__price">
            &euro;
            {{
              parseFloat(paymentTotal)
                .toFixed(2)
                .replace(".", ",")
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="checkout__container">
      <div class="checkout__main-content">
        <checkbox-collapse
          :title="`terms_conditions_checkbox_title` | t"
          :text="termsAndConditions"
          text-has-html
          @accept-terms-conditions="acceptTermsConditions"
        ></checkbox-collapse>
      </div>
    </div>

    <div class="order-review__payment-container checkout__container">
      <div class="checkout__main-content is-narrow">
        <checkout-payment-ideal-selector
          v-if="paymentIsRequired"
          :issuer="order.issuer"
          @ideal-issuer-selected="handleIssuerSelected"
        ></checkout-payment-ideal-selector>

        <button
          v-if="!paymentIsRequired && acceptedTermsConditions"
          class="button--primary is-full-width"
          :disabled="isOrderBusy"
          @click.prevent="startOrder"
        >
          <i v-if="isOrderBusy" class="far fa-spinner fa-spin"></i>
          {{ "Order tickets" | t }}
        </button>

        <button
          v-else-if="allowedToBuy"
          class="button--primary is-full-width"
          :disabled="isOrderBusy"
          @click.prevent="startOrder"
        >
          <i v-if="isOrderBusy" class="far fa-spinner fa-spin"></i>
          {{ "Pay your tickets" | t }}
        </button>

        <div v-else-if="order.issuer && !acceptedTermsConditions">
          <small>{{ "Accept terms and conditions to continue" | t }}</small>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import EventBus from "../../../event-bus";
import order from "../../../services/orderService";
import intoCultureApiErrorHandler from "../../../services/intoCultureApiErrorHandler";
import user from "../../../services/userServices";
import { getOrderTotal, getPaymentTotal } from "../../../helpers/orderHelper";
import {
  PATH_TERMS_OF_SERVICE,
  PATH_PRIVACY_POLICY,
} from "../../../constants/paths";
import OrderCreateError from "../../../errors/OrderCreateError";

export default {
  name: "CheckoutPageOrderReview",
  props: {
    product: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showBankSelector: false,
      acceptedTermsConditions: false,
      paymentTotal: 0,
      userBalance: 0,
      isOrderBusy: false,
    };
  },
  computed: {
    termsAndConditions() {
      const rawHtml = this.$options.filters.t("terms_conditions_checkbox_text");
      return this.replaceTermsAndConditionsUrlPlaceholders(rawHtml);
    },
    header() {
      return {
        title: this.$options.filters.t("Verify your order"),
      };
    },
    hasTickets() {
      return !!this.order.tickets;
    },
    allowedToBuy() {
      return (
        this.hasTickets && this.order.issuer && this.acceptedTermsConditions
      );
    },
    paymentIsRequired() {
      return this.paymentTotal > 0;
    },
  },
  async mounted() {
    EventBus.$emit("mountedCheckoutStep", { step: 3, header: this.header });

    if (this.product.cjp_budget) {
      try {
        this.userBalance = await user.getBalance();
      } catch (err) {
        this.userBalance = 0;
      }
    }

    this.calculatePaymentTotal();
  },
  methods: {
    replaceTermsAndConditionsUrlPlaceholders(rawHtml) {
      return rawHtml
        .replace("{termsOfServiceUrl}", PATH_TERMS_OF_SERVICE)
        .replace("{privacyPolicyUrl}", PATH_PRIVACY_POLICY);
    },
    acceptTermsConditions($event) {
      this.acceptedTermsConditions = $event;
    },
    calculatePaymentTotal() {
      if (!this.hasTickets) {
        return 0;
      }

      const orderTotal = getOrderTotal(this.order.tickets);

      this.paymentTotal = getPaymentTotal(orderTotal, this.userBalance);
    },
    async startOrder() {
      this.isOrderBusy = true;
      try {
        await order.doPayment({
          order: this.order,
          returnTo: location.pathname,
        });
        this.isOrderBusy = false;
      } catch (err) {
        if (err instanceof OrderCreateError) {
          EventBus.$emit("failedCheckoutStep", {
            header: {
              title: this.$options.filters.t(
                "Could not create order, please try again later."
              ),
            },
            errorMessage: err.message,
          });
        }

        intoCultureApiErrorHandler(err);
      } finally {
        this.isOrderBusy = false;
      }
    },
    handleIssuerSelected($event) {
      this.$emit("ideal-issuer-selected", $event);
    },
  },
};
</script>
