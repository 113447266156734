import apiClient from "../services/intoCultureApiClient.js";
import {
  PRODUCT_TYPE_TIMESLOT,
  PRODUCT_TYPE_DATE,
  PRODUCT_TYPE_PERIOD,
} from "../constants/products";
import { isBeforeOrOnSameDate } from "../helpers/dateHelper";

export const validateAvailabilityArguments = ({ slug, startDate }) => {
  if (slug === undefined) {
    throw new TypeError("Missing argument: slug");
  }

  if (startDate === undefined) {
    throw new TypeError("Missing argument: startDate");
  }
};

export const logError = (err) => {
  console.error(err);
};

/**
 * Checks whether the startDate is before or on the availabilty period
 * @param {string} slug
 * @param {string} startDate
 * @returns Boolean
 */
export const hasProductAvailabilityForPeriod = async ({ slug }, startDate) => {
  try {
    validateAvailabilityArguments({ slug, startDate });

    const { availabilityPeriod } = await apiClient.getProductAvailability(
      slug,
      {
        startDate,
      }
    );

    return isBeforeOrOnSameDate(
      new Date(startDate),
      new Date(availabilityPeriod)
    );
  } catch (err) {
    logError(err);

    return false;
  }
};

/**
 * Checks whether the startDate is in the product availability array
 * @param {string} slug
 * @param {string} startDate
 * @returns Boolean
 */
export const hasProductAvailabilityForDate = async ({ slug }, startDate) => {
  try {
    validateAvailabilityArguments({ slug, startDate });

    const { availability } = await apiClient.getProductAvailability(slug, {
      startDate,
    });

    return availability.includes(startDate);
  } catch (err) {
    logError(err);

    return false;
  }
};

/**
 * Checks whether the startDate is a key in the product availability object
 * @param {string} slug
 * @param {string} startDate
 * @returns Boolean
 */
export const hasProductAvailabilityForTimeslot = async (
  { slug },
  startDate
) => {
  try {
    validateAvailabilityArguments({ slug, startDate });

    const { availability } = await apiClient.getProductAvailability(slug, {
      startDate,
    });

    return Object.prototype.hasOwnProperty.call(availability, startDate);
  } catch (err) {
    logError(err);

    return false;
  }
};

export const hasProductAvailability = async (product, startDate) => {
  // Period
  if (product.type === PRODUCT_TYPE_PERIOD) {
    const currentDate = new Date().toJSON().slice(0, 10);

    return await hasProductAvailabilityForPeriod(product, currentDate);
  }

  // Date
  if (product.type === PRODUCT_TYPE_DATE) {
    return await hasProductAvailabilityForDate(product, startDate);
  }

  // Timeslot
  if (product.type === PRODUCT_TYPE_TIMESLOT) {
    return await hasProductAvailabilityForTimeslot(product, startDate);
  }
};

export default {
  hasProductAvailability,
  hasProductAvailabilityForPeriod,
  hasProductAvailabilityForDate,
  hasProductAvailabilityForTimeslot,
};
