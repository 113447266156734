import EventBus from "../event-bus";
import AuthorizationError from "../errors/AuthorizationError";
import MethodNotAllowedError from "../errors/MethodNotAllowedError";

export default (err) => {
  if (err instanceof AuthorizationError) {
    EventBus.$emit("userNotAuthorized");

    // Does not throw an error here as it shall trigger the login flow
    return null;
  }

  if (err instanceof MethodNotAllowedError) {
    // TODO: inform user
    console.warn("Method Not Allowed");
  }

  throw err;
};
