import orderService from "../services/orderService";
import {
  productIsTypePeriod,
  productIsTypeTimeslot,
  productIsTypeDate,
} from "./productHelper";
import {
  PRODUCT_TYPE_TIMESLOT,
  PRODUCT_TYPE_DATE,
} from "../constants/products";

export const getOrderTotal = (tickets) => {
  const totalPrice = tickets.reduce((acc, ticket) => {
    if (!ticket.amount || !ticket.price) {
      return acc;
    }

    return acc + ticket.amount * Number(ticket.price);
  }, 0);

  return totalPrice;
};

export const getPaymentTotal = (orderTotal, budget) => {
  const paymentSubtotal = orderTotal - budget;

  if (paymentSubtotal <= 0) {
    return 0;
  }

  return paymentSubtotal;
};

export const getTicketQuantity = (variants) => {
  return variants.reduce((acc, variant) => {
    return acc + variant.amount;
  }, 0);
};

export const timeslotIsRequiredForProductType = ({ type }) => {
  return type === PRODUCT_TYPE_TIMESLOT;
};

export const dateIsRequiredForProductType = ({ type }) => {
  return type === PRODUCT_TYPE_TIMESLOT || type === PRODUCT_TYPE_DATE;
};

export const orderIsValidForProduct = (order, product) => {
  if (!order) return false;

  if (productIsTypePeriod(product) && orderService.orderQuantity() > 0) {
    return true;
  }

  if (
    productIsTypeDate(product) &&
    order.date &&
    orderService.orderQuantity() > 0
  ) {
    return true;
  }

  if (
    productIsTypeTimeslot(product) &&
    order.date &&
    order.timeslot &&
    orderService.orderQuantity() > 0
  ) {
    return true;
  }

  return false;
};

export default {
  getOrderTotal,
  getPaymentTotal,
  getTicketQuantity,
  orderIsValidForProduct,
  timeslotIsRequiredForProductType,
  dateIsRequiredForProductType,
};
