<template>
  <div class="checkout-page-wrapper">
    <popup-active-membership-required
      :open="activeMembershipRequired"
      :can-close="false"
    ></popup-active-membership-required>
    <checkout-header
      :title="'Order here' | t"
      :info="'Discounts are calculated automatically' | t"
      :logo="headerBlock.logo"
    ></checkout-header>

    <template v-if="!initialLoad">
      <checkout-page-order-selector
        v-if="currentStep === 1"
        :product="product"
        @confirm-order="handleOrderConfirm"
      ></checkout-page-order-selector>

      <checkout-page-login
        v-else-if="currentStep === 2"
        @loggedin="currentStep = 3"
      ></checkout-page-login>

      <checkout-page-order-review
        v-else-if="currentStep === 3"
        :product="product"
        :order="order"
        @edit-order="currentStep = 1"
        @ideal-issuer-selected="setSelectedIdealIssuer"
      ></checkout-page-order-review>
    </template>
  </div>
</template>

<script>
import EventBus from "../../../event-bus";
import { orderIsValidForProduct } from "../../../helpers/orderHelper";
import orderService from "../../../services/orderService";
import user from "../../../services/userServices/userService";

export default {
  name: "CheckoutSteps",
  props: {
    headerBlock: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      initialLoad: true,
      currentStep: 1,
      order: {},
      isLoggedIn: false,
      activeMembershipRequired: false,
    };
  },
  computed: {},
  watch: {
    currentStep() {
      EventBus.$emit("checkoutStepChange", { currentStep: this.currentStep });
      this.scrollToTop();
    },
  },
  async created() {
    this.setEventHandlers();
    this.isLoggedIn = await user.isAuthorized();

    const order = orderService.loadOrder(this.product);

    if (orderIsValidForProduct(order, this.product)) {
      this.order = order;
    }

    // at which step were we?
    const urlParams = new URLSearchParams(window.location.search);
    const queryStep = parseInt(urlParams.get("step"));

    if (queryStep && queryStep > 0 && queryStep < 4) {
      if (queryStep === 3) {
        this.currentStep = this.isLoggedIn ? queryStep : 2;
      } else {
        this.currentStep = queryStep;
      }
    }
    this.initialLoad = false;
  },
  async mounted() {
    if (this.isLoggedIn) {
      const activeMembershipRequired = orderService.requiresActiveMembership(
        this.product
      );
      if (
        !activeMembershipRequired ||
        (activeMembershipRequired && (await user.hasActiveMembership()))
      ) {
        // ok
      } else {
        this.activeMembershipRequired = true;
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    setEventHandlers() {
      EventBus.$on("userNotAuthorized", () => {
        this.currentStep = 2;
      });
    },
    async handleOrderConfirm(order) {
      this.order = order;

      this.currentStep = this.isLoggedIn ? 3 : 2;
    },
    setSelectedIdealIssuer(issuerCode) {
      // bank info is not stored via orderService/localStorage
      this.order = {
        ...this.order,
        paymentMethod: "ideal",
        issuer: issuerCode,
      };
    },
  },
};
</script>
