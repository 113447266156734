<template>
  <checkout-section :number="1" :title="'Pick a date' | t">
    <template #content>
      <date-picker
        v-model="selectedDate"
        mode="date"
        title-position="left"
        :columns="$screens({ default: 1, md: 2 })"
        :step="1"
        :min-date="new Date()"
        :model-config="modelConfig"
        is-expanded
        color="pinkly"
        locale="nl"
        @dayclick="handleDateSelection"
      />
    </template>
  </checkout-section>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "CheckoutDateSelector",
  components: { DatePicker },
  props: {
    selected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedDate: "",
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    selected() {
      this.setSelectedDate();
    },
  },
  mounted() {
    this.setSelectedDate();
  },
  methods: {
    setSelectedDate() {
      if (this.selected !== "") {
        this.selectedDate = this.selected;
      }
    },
    handleDateSelection(day) {
      this.$emit("date-selected", day.id);
    },
  },
};
</script>
