<template>
  <transition name="slide-modal">
    <div class="ideal-selector__modal">
      <div class="ideal-selector__modal__header">
        <h3 class="title-s">
          {{ "Pay with iDeal" | t }} <i class="icon--ideal"></i>
        </h3>

        <div
          class="ideal-selector__modal__btn-close"
          @click.prevent="$emit('ideal-modal-close')"
        >
          <i class="fal fa-times"></i>
        </div>
      </div>

      <div class="ideal-selector__modal__content">
        <h4>{{ "Select your bank" | t }}</h4>
        <div
          v-for="issuer in idealIssuers"
          :key="issuer.code"
          @click="handleIdealIssuerSelection(issuer)"
        >
          <label class="radio">
            <input type="radio" name="selected-issuer" />
            <span class="checkmark"></span>
            <i
              class="icon--ideal-bank"
              :style="{ 'background-image': `url('${issuer.image}')` }"
            ></i>
            {{ issuer.name }}
          </label>
        </div>
      </div>

      <div class="ideal-selector__modal__footer">
        <button
          class="button--secondary is-full-width"
          @click.prevent="$emit('ideal-modal-close')"
        >
          {{ "Continue" | t }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import apiClient from "../../../services/intoCultureApiClient.js";

export default {
  name: "CheckoutPaymentIdealModal",
  props: {
    issuerCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      idealIssuers: null,
    };
  },
  async created() {
    this.loadIdealIssuers();
  },
  methods: {
    async loadIdealIssuers() {
      try {
        const { ideal } = await apiClient.getIdealIssuers();

        this.idealIssuers = ideal;
      } catch (e) {
        console.error(e.message);
      }
    },
    handleIdealIssuerSelection(issuer) {
      this.$emit("ideal-issuer-selected", issuer);
    },
  },
};
</script>
