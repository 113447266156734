<template>
  <checkout-section :number="3" title="Selecteer het aantal tickets">
    <template #content>
      <div class="ticket-table__row ticket-table__header">
        <div class="ticket-table__title">
          {{ "Tickets" | t }}
        </div>
        <div class="ticket-table__title">
          {{ "Price" | t }}
        </div>
        <div class="ticket-table__title">
          {{ "Amount" | t }}
        </div>
        <div class="ticket-table__title ticket-table__title--total">
          {{ "Subtotal" | t }}
        </div>
      </div>
      <div
        v-for="variant in localVariants"
        :key="variant.slug"
        class="ticket-table__row"
      >
        <div
          :key="`${variant.slug}-tickets`"
          class="ticket-table__field ticket-table__field--tickets"
        >
          {{ variant.name }}
        </div>

        <div
          :key="`${variant.slug}-price`"
          class="ticket-table__field ticket-table__field--price"
        >
          <span
            v-if="regularPrice(variant.price, variant.name)"
            style="text-decoration: line-through; color: orange"
            >&euro; {{ regularPrice(variant.price, variant.name) }}<br
          /></span>
          &euro;
          {{
            parseFloat(variant.price, variant.name)
              .toFixed(2)
              .replace(".", ",")
          }}
        </div>

        <div
          :key="`${variant.slug}-amount`"
          class="ticket-table__field ticket-table__field--amount"
        >
          <input-tally-counter
            v-model="variant.amount"
            :max="maxQuantity"
            @input="$emit('tickets-selected', localVariants)"
            @subtract="ticketRemove(variant)"
            @add="ticketAdd(variant)"
          ></input-tally-counter
          ><br />
          <small>Max. {{ maxQuantity }} per bestelling</small>
        </div>

        <div
          :key="`${variant.slug}-totals`"
          class="ticket-table__field ticket-table__field--total"
        >
          &euro;
          {{ (variant.amount * variant.price).toFixed(2).replace(".", ",") }}
        </div>
      </div>

      <div class="ticket-table__footer">
        <div class="ticket-table__footer__label">{{ "Total" | t }}:</div>
        <div class="ticket-table__footer__price">
          &euro; {{ paymentTotal.toFixed(2).replace(".", ",") }}
        </div>
      </div>
    </template>
  </checkout-section>
</template>

<script>
import {
  getOrderTotal,
  getPaymentTotal,
  getTicketQuantity,
} from "../../../helpers/orderHelper";

export default {
  name: "CheckoutTicketSelector",
  props: {
    date: {
      type: String,
      default: null,
    },
    productVariants: {
      type: Array,
      required: true,
    },
    previouslySelectedTickets: {
      type: Array,
      required: true,
    },
    maxQuantity: {
      type: Number,
      default: 10,
    },
    userBalance: {
      type: Number,
      default: 0,
    },
    productDiscount: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localVariants: [],
      paymentTotal: 0,
    };
  },
  computed: {
    ticketQuantity() {
      return getTicketQuantity(this.localVariants);
    },
    addAllowed() {
      return this.ticketQuantity < this.maxQuantity;
    },
  },
  watch: {
    productVariants() {
      this.mapToLocalVariants();
    },
    date() {
      this.mapToLocalVariants();
    },
  },
  mounted() {
    this.mapToLocalVariants();
    this.calculatePaymentTotal();
  },
  methods: {
    mapToLocalVariants() {
      let variantsToMap = this.productVariants;
      if (this.date) {
        const date = new Date(this.date);
        const allDayName = [
          "",
          "maandag",
          "dinsdag",
          "woensdag",
          "donderdag",
          "vrijdag",
          "zaterdag",
          "zondag",
        ];
        const dayNameToday = allDayName[date.getDay()];
        const variantsWithDayName = variantsToMap.filter((value) => {
          if (value.name.toLowerCase().indexOf(dayNameToday) !== -1) {
            return true; // Show tickets that have the selected day name in their name
          } else {
            // Also show 'generic' tickets without any day name in them.
            let ticketVariantCarriesDayName = false;
            for (const oneDayName of allDayName) {
              if (
                oneDayName !== "" &&
                value.name.toLowerCase().indexOf(oneDayName) !== -1
              ) {
                ticketVariantCarriesDayName = true;
              }
            }
            return ticketVariantCarriesDayName === false; // only show variant if it does not carry a day name
          }
        });
        if (variantsWithDayName.length > 0) {
          variantsToMap = variantsWithDayName;
        }
      }
      this.localVariants = variantsToMap.map((variant) => ({
        amount: 0,
        ...variant,
      }));

      // Now somehow we seem to be able to reload a left-behind cart from localstorage, so try to reload the amounts
      // from local storage here.
      for (const previouslySelectedVariant of this.previouslySelectedTickets) {
        for (const variant of this.localVariants) {
          if (
            variant.id &&
            variant.id === previouslySelectedVariant.id &&
            previouslySelectedVariant.amount > 0
          ) {
            variant.amount = previouslySelectedVariant.amount;
          }
        }
      }
      // clean out any previously selected in the parent view after remapping.
      this.$emit("tickets-selected", this.localVariants);
    },
    regularPrice(price, name) {
      if (name.toLowerCase().indexOf("cjp") === -1) {
        // Do not apply discount text on products that don't carry "cjp" in the name
        return null;
      }

      price = parseFloat(price);

      if (!this.productDiscount) {
        return null; // No discount text available
      } else if (this.productDiscount.indexOf("tot") !== -1) {
        return null; // unknown old price.
      } else if (this.productDiscount.indexOf("%") !== -1) {
        // percentage discount
        const discountFactor =
          parseFloat(this.productDiscount.match(/\d+,?/)[0].replace(",", ".")) /
          100;
        return (Math.round((price / (1 - discountFactor)) * 100) / 100)
          .toFixed(2)
          .replace(".", ",");
      } else if (
        this.productDiscount.indexOf("€") !== -1 &&
        this.productDiscount.indexOf("korting") !== -1
      ) {
        // assume amount discount
        const discountAmount = parseFloat(
          this.productDiscount
            .replace(",-", ",00")
            .match(/[\d,]+/)[0]
            .replace(",", ".")
        );
        return (price + discountAmount).toFixed(2).replace(".", ",");
      }
      return null;
    },
    calculatePaymentTotal() {
      const orderTotal = getOrderTotal(this.localVariants);

      this.paymentTotal = getPaymentTotal(orderTotal, this.userBalance);
    },
    ticketAdd(variant) {
      if (!this.addAllowed) {
        return;
      }

      variant.amount++;

      this.calculatePaymentTotal();
      this.$emit("tickets-selected", this.localVariants);
    },
    ticketRemove(variant) {
      if (variant.amount <= 0) {
        variant.amount = 0;
        return;
      }

      variant.amount--;
      this.calculatePaymentTotal();
      this.$emit("tickets-selected", this.localVariants);
    },
  },
};
</script>
