<template>
  <main>
    <div class="checkout__container">
      <div class="checkout__main-content notice-pane">
        <h2 class="title-m--alt">
          {{ "Unfortunately something went wrong" | t }}
        </h2>
        <p>{{ message | t }}</p>
        <a :href="productUrl" class="button--primary"
          >{{ "Back to" | t }} {{ product.name }}</a
        >
      </div>
    </div>
  </main>
</template>

<script>
import { LOCAL_STORAGE_GLOBALS } from "../../../constants/localStorage";

export default {
  name: "NoticePane",
  props: {
    message: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    productUrl() {
      const { slugProducts } = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_GLOBALS)
      );
      return `/${slugProducts}/${this.product.slug}`;
    },
  },
};
</script>
