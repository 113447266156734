<template>
  <div class="order-review__order-card">
    <div class="order-review__order-card__image">
      <vue-image :srcset="product.images.mobile"></vue-image>
    </div>
    <div class="order-review__order-card__content">
      <h2 class="title-s">{{ product.name }}</h2>

      <time v-if="dateIsRequiredForProductType(product) && dateFormat">{{
        dateFormat
      }}</time>
      <time v-if="timeslotIsRequiredForProductType(product) && order.timeslot"
        >{{ "Timeslot" | t }}: {{ order.timeslot }}</time
      >
    </div>
  </div>
</template>

<script>
import {
  timeslotIsRequiredForProductType,
  dateIsRequiredForProductType,
} from "../../../helpers/orderHelper";

export default {
  name: "CheckoutReviewOrderCard",
  props: {
    product: {
      required: true,
      type: Object,
    },
    order: {
      required: true,
      type: Object,
    },
  },
  computed: {
    dateFormat() {
      if (!this.order.date) return null;

      const orderDate = new Date(this.order.date);

      return (
        this.ucFirst(this.fullDay(orderDate)) +
        ", " +
        orderDate.getDate() +
        " " +
        this.fullMonth(orderDate) +
        " " +
        orderDate.getFullYear()
      );
    },
  },
  methods: {
    timeslotIsRequiredForProductType,
    dateIsRequiredForProductType,
    ucFirst(s) {
      if (typeof s !== "string") {
        return;
      }

      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    fullDay(orderDate) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      return this.$options.filters.t(days[orderDate.getDay()]);
    },
    fullMonth(orderDate) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return this.$options.filters.t(months[orderDate.getMonth()]);
    },
  },
};
</script>
