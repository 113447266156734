<template>
  <div>
    <checkout-header
      :title="'Login' | t"
      :logo="headerBlock.logo"
    ></checkout-header>
  </div>
</template>

<script>
export default {
  headerBlock: {
    type: Object,
    required: true,
  },
};
</script>
