<template>
  <main>
    <div class="checkout__container has-sidebar">
      <div class="checkout__sidebar">
        <div class="order-summary">
          <div class="order-summary__content">
            {{ "You selected" | t }}<br />
            <strong>{{ product.name }}</strong>
          </div>

          <div class="order-summary__image">
            <vue-image :srcset="product.images.mobile"></vue-image>
          </div>
        </div>
      </div>

      <div class="checkout__main-content">
        <checkout-date-selector
          v-if="dateIsRequiredForProductType(product)"
          :selected="selectedDate"
          @date-selected="setSelectedDate"
        ></checkout-date-selector>

        <checkout-timeslot-selector
          v-if="timeslotIsRequiredForProductType(product)"
          :slug="product.slug"
          :date="selectedDate"
          :order-quantity="orderQuantity"
          :selected="selectedTimeslot"
          @timeslot-selected="setSelectedTimeslot"
          @timeslot-quantity="setTimeslotQuantity"
        ></checkout-timeslot-selector>

        <checkout-ticket-selector
          :date="selectedDate"
          :product-variants="availableTickets"
          :previously-selected-tickets="selectedTickets"
          :product-discount="product.discount_label"
          :max-quantity="maxQuantity"
          :user-balance="userBalance"
          @tickets-selected="setSelectedTickets"
        ></checkout-ticket-selector>
      </div>
    </div>

    <checkout-sticky-footer
      v-if="orderQuantity > 0"
      :order-quantity="orderQuantity"
      :order-total="paymentTotal"
      :confirm-disabled="loading || !nextStepIsAllowed"
      :status-message="errorMessage || statusMessage"
      @confirm="handleOrderConfirm"
    ></checkout-sticky-footer>
  </main>
</template>

<script>
import EventBus from "../../../event-bus";
import orderService from "../../../services/orderService";
import user from "../../../services/userServices";
import { hasProductAvailability } from "../../../helpers/productAvailabilityHelper";
import {
  getOrderTotal,
  getPaymentTotal,
  timeslotIsRequiredForProductType,
  dateIsRequiredForProductType,
} from "../../../helpers/orderHelper";

export default {
  name: "CheckoutPageOrderSelector",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTickets: [],
      availableTickets: [],
      selectedDate: "",
      selectedTimeslot: null,
      paymentTotal: 0,
      userBalance: 0,
      nextStepIsAllowed: false,
      errorMessage: "",
      loading: false,
      maxQuantity: 10,
    };
  },
  computed: {
    header() {
      return {
        title: this.$options.filters.t("Order here"),
        info: this.$options.filters.t("Discounts are calculated automatically"),
      };
    },
    orderQuantity() {
      return this.selectedTickets.reduce((acc, ticket) => {
        return acc + ticket.amount;
      }, 0);
    },
    order() {
      return {
        productSlug: this.product.slug,
        selectedTickets: this.selectedTickets,
        tickets: this.availableTickets, // tickets is mapped from this.product.variants from API.
        ...(this.dateIsRequiredForProductType(this.product) && {
          date: this.selectedDate,
        }),
        ...(this.timeslotIsRequiredForProductType(this.product) && {
          timeslot: this.selectedTimeslot,
        }),
      };
    },
    statusMessage() {
      if (this.selectedDate && this.selectedTimeslot) {
        return null;
      }

      const toDos = this.getToDos();

      if (!toDos.length) return null;

      const andString = this.$options.filters.t("and");

      return this.$options.filters
        .t("Pick a {todo} to continue")
        .replace("{todo}", toDos.join(` ${andString} `));
    },
  },
  watch: {
    async selectedDate() {
      if (this.timeslotIsRequiredForProductType(this.product)) {
        this.selectedTimeslot = null;
      }

      this.nextStepIsAllowed = await this.checkIfNextStepIsAllowed();
    },
    async selectedTimeslot() {
      this.nextStepIsAllowed = await this.checkIfNextStepIsAllowed();
    },
  },
  created() {
    EventBus.$emit("mountedCheckoutStep", { step: 1, header: this.header });

    const { selectedTickets, tickets, date, timeslot } = orderService.loadOrder(
      this.product
    );

    if (tickets.length) {
      this.availableTickets = tickets;
    }
    if (selectedTickets && selectedTickets.length) {
      this.selectedTickets = selectedTickets;
    }
    this.selectedDate = date;
    this.selectedTimeslot = timeslot;
  },
  async mounted() {
    if (this.dateIsRequiredForProductType(this.product)) {
      const today = new Date();
      today.setDate(today.getDate());
      // Set to today if selected date not set or selectedDate is before today.
      if (
        !this.selectedDate ||
        this.selectedDate === "" ||
        new Date(this.selectedDate) < new Date()
      ) {
        this.selectedDate = today.toISOString().substring(0, 10); // set default date.
      }
    }
    if (this.product.cjp_budget) {
      try {
        this.userBalance = await user.getBalance();
      } catch (err) {
        this.userBalance = 0;
      }
    }
    this.maxQuantity = this.product.cjp_budget ? 1 : 10;

    this.calculatePaymentTotal();
  },
  methods: {
    timeslotIsRequiredForProductType,
    dateIsRequiredForProductType,
    setErrorMessage(message = "") {
      this.errorMessage = message;
    },
    clearErrorMessage() {
      this.setErrorMessage("");
    },
    setTimeslotQuantity(quantity) {
      if (quantity < 10) {
        this.maxQuantity = quantity;
      } else {
        this.maxQuantity = 10; // 10 is global ticket limited.
      }
    },
    async checkIfNextStepIsAllowed() {
      if (
        this.dateIsRequiredForProductType(this.product) &&
        !this.selectedDate
      ) {
        return false;
      }

      if (
        this.timeslotIsRequiredForProductType(this.product) &&
        !this.selectedTimeslot
      ) {
        return false;
      }

      if (!this.timeslotIsRequiredForProductType(this.product)) {
        return this.checkProductAvailability();
      }

      return true;
    },
    async checkProductAvailability() {
      this.loading = true;
      const hasAvailability = await hasProductAvailability(
        this.product,
        this.selectedDate
      );
      this.loading = false;

      if (!hasAvailability) {
        this.setErrorMessage(
          this.$options.filters.t("This product is unavailable")
        );
      }

      return hasAvailability;
    },
    calculatePaymentTotal() {
      const orderTotal = getOrderTotal(this.selectedTickets);

      this.paymentTotal = getPaymentTotal(orderTotal, this.userBalance);
    },
    setSelectedTickets(selectedTickets) {
      this.clearErrorMessage();
      this.selectedTickets = selectedTickets;
      this.checkIfNextStepIsAllowed();
      this.localPersistOrder();
      this.calculatePaymentTotal();
    },
    setSelectedDate(selectedDate) {
      this.selectedDate = selectedDate;
      this.localPersistOrder();
    },
    setSelectedTimeslot(selectedTimeslot) {
      this.selectedTimeslot = selectedTimeslot;
      this.localPersistOrder();
    },
    getToDos() {
      const toDos = [];

      if (
        this.dateIsRequiredForProductType(this.product) &&
        !this.selectedDate
      ) {
        toDos.push(this.$options.filters.t("date"));
      }

      if (
        this.timeslotIsRequiredForProductType(this.product) &&
        !this.selectedTimeslot
      ) {
        toDos.push(this.$options.filters.t("timeslot"));
      }

      return toDos;
    },
    localPersistOrder() {
      orderService.persistOrder(this.order);
    },
    handleOrderConfirm() {
      if (this.product.cjp_budget && this.paymentTotal > 0) {
        this.errorMessage = this.$options.filters.t("Insufficient balance");

        return false;
      }

      const submitOrder = this.order;
      submitOrder.tickets = this.order.selectedTickets; // map selected tickets to tickets again for post to api.

      this.$emit("confirm-order", submitOrder);
    },
  },
};
</script>
