import {
  PRODUCT_TYPE_TIMESLOT,
  PRODUCT_TYPE_DATE,
  PRODUCT_TYPE_PERIOD,
} from "../constants/products";

export const productIsTypePeriod = ({ type }) => {
  return type === PRODUCT_TYPE_PERIOD;
};

export const productIsTypeTimeslot = ({ type }) => {
  return type === PRODUCT_TYPE_TIMESLOT;
};

export const productIsTypeDate = ({ type }) => {
  return type === PRODUCT_TYPE_DATE;
};

export default {
  productIsTypePeriod,
  productIsTypeTimeslot,
  productIsTypeDate,
};
