<template>
  <header
    class="checkout-header grid-container"
    :class="{ 'is-error': localAlertStatus === 'error' }"
  >
    <a href="/" class="checkout-header__logo">
      <img :src="logo.url" :alt="logo.title" />
    </a>

    <div v-if="localTitle" class="checkout-header__title">
      <h1>{{ localTitle }}</h1>
    </div>

    <div v-if="localInfo" class="checkout-header__info">
      <i class="fas fa-info-circle"></i> {{ localInfo }}
    </div>

    <div class="checkout-header__progress-bar">
      <div
        class="checkout-header__progress"
        :style="{ width: progressPercentage() }"
      ></div>
    </div>
  </header>
</template>

<script>
import EventBus from "../../../event-bus";

export default {
  name: "CheckoutHeader",
  props: {
    step: {
      type: Number,
      required: false,
      default: 0,
    },
    totalSteps: {
      type: Number,
      required: false,
      default: 4,
    },
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: false,
      default: null,
    },
    logo: {
      type: Object,
      required: true,
    },
    alertStatus: {
      type: String,
      required: false,
      default: "info",
    },
  },
  data() {
    return {
      localStep: 0,
      localTitle: null,
      localInfo: null,
      localAlertStatus: "info",
    };
  },
  mounted() {
    const { step, title, info, alertStatus } = this;

    if (step) {
      this.localStep = step;
    }

    if (title) {
      this.localTitle = title;
    }

    if (info) {
      this.localInfo = info;
    }

    this.localAlertStatus = alertStatus;

    this.setEventHandlers();
  },
  methods: {
    setEventHandlers() {
      EventBus.$on(
        "mountedCheckoutStep",
        ({ step, header: { title, info } }) => {
          this.localAlertStatus = "info";
          this.localTitle = title || null;
          this.localInfo = info || null;

          if (step) {
            this.localStep = step;
          }
        }
      );

      EventBus.$on("failedCheckoutStep", ({ step, header: { title } }) => {
        this.localAlertStatus = "error";
        this.localTitle = title;
        this.localInfo = null;

        if (step) {
          this.localStep = step;
        }
      });
    },
    progressPercentage() {
      return `${(this.localStep / this.totalSteps) * 100}%`;
    },
  },
};
</script>
