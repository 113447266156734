<template>
  <div>
    <main>
      <div class="checkout__container">
        <div class="checkout__main-content">
          <login-pane
            :helptext="`Use your account to login and complete your order` | t"
            :return-to="returnTo"
          ></login-pane>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EventBus from "../../../event-bus";
import LoginPane from "./LoginPane.vue";

export default {
  name: "CheckoutPageLogin",
  components: {
    LoginPane,
  },
  data() {
    return {
      authUrl: null,
    };
  },
  computed: {
    header() {
      return {
        title: this.$options.filters.t("Login"),
      };
    },
    returnTo() {
      return location.pathname + "?step=3";
    },
  },
  async created() {
    EventBus.$emit("mountedCheckoutStep", { step: 2, header: this.header });
  },
};
</script>
