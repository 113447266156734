<template>
  <div class="login-pane">
    <div class="login-pane__help-text">
      <h2 class="title-m--alt">{{ "Log into your account" | t }}</h2>
      <p v-if="helptext">{{ helptext }}</p>
    </div>
    <button class="button--primary" @click.prevent="login">
      {{ "Login" | t }}
    </button>
  </div>
</template>

<script>
import user from "../../../services/userServices/userService";

export default {
  name: "LoginPaneOauth",
  props: {
    helptext: {
      type: String,
      default: null,
    },
    returnTo: {
      type: String,
      default: null,
    },
  },
  methods: {
    async login() {
      const loginParams = { returnTo: document.referrer };

      if (this.returnTo) {
        loginParams.returnTo = this.returnTo;
      }
      await user.doLogin(loginParams);
    },
  },
};
</script>
