<template>
  <sticky-bar position="bottom">
    <div class="checkout-footer">
      <div class="checkout-footer__total-amount">{{ orderQuantity }}x</div>
      <div class="checkout-footer__total-price">
        {{ "Total" | t }} &euro; {{ orderTotal.toFixed(2).replace(".", ",") }}
      </div>
      <div v-if="statusMessage" class="checkout-footer__status-text">
        {{ statusMessage }}
      </div>
      <div class="checkout-footer__cta">
        <button
          class="button--primary"
          :class="{ 'is-disabled': confirmDisabled }"
          :disabled="confirmDisabled"
          @click.prevent="$emit('confirm')"
        >
          {{ "Continue your order" | t }}
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </sticky-bar>
</template>

<script>
export default {
  name: "CheckoutStickyFooter",
  props: {
    orderQuantity: {
      type: Number,
      required: true,
    },
    orderTotal: {
      type: Number,
      required: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    statusMessage: {
      type: String,
      default: null,
    },
  },
};
</script>
