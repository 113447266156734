<template>
  <div class="checkbox-collapse">
    <div>
      <div
        class="checkbox"
        :class="{ selected: selected }"
        @click="toggleSelected"
      ></div>
    </div>

    <div>
      <div class="checkbox-collapse__title" @click="toggleSelected">
        {{ title }}
      </div>
      <div
        ref="collapsableText"
        class="checkbox-collapse__text"
        :class="{ 'is-collapsed': selected }"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="textHasHtml" v-html="text"></span>
        <span v-else>{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxCollapse",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    textHasHtml: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      collapseHeight: 0,
    };
  },
  watch: {
    selected(value) {
      if (value) {
        this.$refs.collapsableText.style.maxHeight = 0;
      } else {
        this.$refs.collapsableText.style.maxHeight = this.collapseHeight;
      }
    },
  },
  mounted() {
    this.collapseHeight = this.$refs.collapsableText.offsetHeight + "px";
    this.$refs.collapsableText.style.maxHeight = this.collapseHeight;
  },
  methods: {
    toggleSelected() {
      this.selected = !this.selected;

      this.$emit("accept-terms-conditions", this.selected);
    },
  },
};
</script>
