const gtmPurchaseEvent = {
  data: null,
  event: null,

  init(orderData) {
    this.data = orderData;
    return this;
  },

  calculateTotalRevenue() {
    return this.data.lines.reduce((total, line) => {
      const lineTotal = parseFloat(line.price) * line.quantity;
      return total + lineTotal;
    }, 0);
  },

  getOrderId() {
    return this.data.id;
  },

  mapToEvent() {
    this.event = {
      event: "purchase",
      ecommerce: {
        currency: "EUR",
        transaction_id: this.getOrderId(),
        value: this.calculateTotalRevenue(),
        tax: 0,
        shipping: 0,
        coupon: "",
        items: this.mapOrderLinesToPurchaseItems(),
      },
    };
  },

  mapOrderLinesToPurchaseItems() {
    return this.data.lines.map((line) => ({
      item_id: line.product_variant.id,
      item_name: line.product_variant.name,
      price: line.price,
      quantity: line.quantity,
    }));
  },

  push() {
    this.mapToEvent();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(this.event);
  },
};

export default gtmPurchaseEvent;
