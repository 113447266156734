<template>
  <div v-if="!isLoading">
    <popup-active-membership-required
      :open="activeMembershipRequired"
      :can-close="false"
    ></popup-active-membership-required>
    <checkout-header
      :title="'Login' | t"
      :logo="headerBlock.logo"
    ></checkout-header>

    <alert-message type="danger" :message="errorMsg"></alert-message>

    <notice-pane v-if="noticeMsg" :message="noticeMsg" :product="product" />

    <template v-if="!isLoggedIn">
      <login-pane
        :helptext="'Log in to see this offer' | t"
        :return-to="returnTo"
        :account-links="accountLinks"
        :support-link="supportLink"
      ></login-pane>
    </template>
  </div>
  <div v-else>
    <div class="spinner"></div>
  </div>
</template>

<script>
import order from "../../../services/orderService";

import AuthorizationError from "../../../errors/AuthorizationError";
import UserAuthenticationError from "../../../errors/UserAuthenticationError";
import IntoCultureApiClientError from "../../../errors/IntoCultureApiClientError";
import ProductNotFoundError from "../../../errors/ProductNotFoundError";
import user from "./../../../services/userServices/userService";

export default {
  name: "CheckoutClickOut",
  props: {
    headerBlock: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    accountLinks: {
      type: Array,
      default: null,
    },
    supportLink: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      loginhref: "/login",
      isLoggedIn: true,
      returnTo: location.href,
      errorMsg: null,
      noticeMsg: null,
      activeMembershipRequired: false,
    };
  },
  async mounted() {
    if (await user.isAuthenticated()) {
      const activeMembershipRequired = order.requiresActiveMembership(
        this.product
      );
      if (
        !activeMembershipRequired ||
        (activeMembershipRequired && (await user.hasActiveMembership()))
      ) {
        await this.goToProduct();
      } else {
        this.activeMembershipRequired = true;
        this.isLoading = false;
      }
    } else {
      this.isLoggedIn = false;
      this.isLoading = false;
    }
  },
  methods: {
    async goToProduct() {
      try {
        await order.doClickOutRedirect(this.product);
      } catch (err) {
        this.isLoading = false;

        if (
          err instanceof AuthorizationError ||
          err instanceof UserAuthenticationError
        ) {
          // need to login first
          this.isLoggedIn = false;
        } else if (err instanceof ProductNotFoundError) {
          this.noticeMsg = err.message;
        } else if (err instanceof IntoCultureApiClientError) {
          const slugProducts = window.dataStatic.slug.products;
          const productUrl = `/${slugProducts}/${this.product.slug}`;

          this.isLoggedIn = false;
          this.setErrorMsg(
            this.$options.filters
              .t("Something went wrong: {error}")
              .replace("{error}", err.message) +
              "<br />" +
              this.$options.filters
                .t("Try to login again or return to {returnTo}")
                .replace(
                  "{returnTo}",
                  `<a href="${productUrl}">${this.product.name}</a>`
                )
          );
          throw err;
        } else {
          throw err;
        }
      }
    },
    setErrorMsg(msg) {
      this.errorMsg = msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  height: 100vh;
}
</style>
