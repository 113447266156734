<template>
  <main v-if="localErrorMessage" class="checkout__container">
    <div class="checkout__main-content is-narrow--centered">
      <div class="checkout__content-block">
        <small>{{ localErrorMessage }}</small>
      </div>

      <button
        v-if="localCtaType === 'login'"
        class="button--primary is-full-width"
        @click.prevent="login"
      >
        Opnieuw inloggen
      </button>
    </div>
  </main>
</template>

<script>
import EventBus from "../../../event-bus";
import user from "../../../services/userServices/userService";
import { LOCAL_STORAGE_REDIRECT_URL } from "../../../constants/localStorage";

export default {
  name: "CheckoutPageErrorMessage",
  data() {
    return {
      localErrorMessage: null,
      localCtaType: null,
    };
  },
  mounted() {
    this.setEventHandlers();
  },
  methods: {
    setEventHandlers() {
      EventBus.$on(
        "failedCheckoutStep",
        ({ errorMessage, cta: { type: ctaType } }) => {
          this.localErrorMessage = errorMessage || null;
          this.localCtaType = ctaType || null;
        }
      );
    },
    async login() {
      if (this.loginhref) {
        localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, location.pathname);
        location.href = this.loginhref;
      }
      await user.doLogin({ returnTo: location.pathname });
    },
  },
};
</script>
